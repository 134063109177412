@font-face {
	font-family: 'TradeGothicLT-Bold';
	src: url('../../assets/fonts/TradeGothicLT-Bold.eot');
	src: url('../../assets/fonts/TradeGothicLT-Bold.eot?#iefix')
			format('embedded-opentype'),
		url('../../assets/fonts/TradeGothicLT-Bold.svg#TradeGothicLT-Bold')
			format('svg'),
		url('../../assets/fonts/TradeGothicLT-Bold.ttf') format('truetype'),
		url('../../assets/fonts/TradeGothicLT-Bold.woff') format('woff'),
		url('../../assets/fonts/TradeGothicLT-Bold.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
