@import '../../shared/theme/colors.scss';
@import '../../shared/theme/variables.scss';
@import '../../shared/theme/typography.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 375px;
	margin: 0 auto;
	height: 100%;
	background-color: $white;
	z-index: 2;

	.modalHeader {
		box-sizing: border-box;
		position: relative;
		padding: 20px 16px;
		display: flex;
		justify-content: center;

		.closeIcon {
			position: absolute;
			top: calc(50% - 13px);
			left: 16px;
			width: 26px;
			height: 26px;
			cursor: pointer;
		}

		.title {
			font-family: 'Bebas Neue', cursive;
			font-size: 20px;
			color: $black;
		}
	}

	.bigImage {
		box-sizing: border-box;
		width: 100%;
		height: 200px;
		margin-bottom: 40px;
		background-color: $accent;
		background-size: cover;
		background-position: center;
		position: relative;

		.price {
			box-sizing: border-box;
			position: absolute;
			left: 20px;
			bottom: -17px;
			display: block;
			width: 84px;
			height: 34px;
			background-color: $black;
			border-radius: 17px;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				font-family: 'Bebas Neue', cursive;
				font-size: 18px;
				color: $white;
			}
		}
	}

	.paragraph {
		box-sizing: border-box;
		padding: 0 20px;
		.title {
			display: block;
			font-family: 'Bebas Neue', cursive;
			font-size: 18px;
			color: $black;
		}

		.content {
			font-family: 'TradeGothicLT-Bold';
			font-size: 14px;
			color: $black;
		}
	}

	.bottom {
		box-sizing: border-box;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 20px 20px 32px;
		display: flex;
		justify-content: center;
		align-items: center;

		.addButton {
			background-color: $accent;
			border-radius: $modal-button-border-radius;
			width: 100%;
			height: 45px;
			box-shadow: 0 2px 6px 0 #00000033;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			span {
				color: $white;
				font-family: 'Bebas Neue', cursive;
				font-size: 22px;
			}

			img {
				margin-left: 14px;
			}
		}

		.qtyManager {
			background-color: $accent;
			border-radius: $modal-button-border-radius;
			width: 100%;
			height: 45px;
			box-shadow: 0 2px 6px 0 #00000033;
			display: flex;
			justify-content: space-between;
			align-items: center;

			img {
				cursor: pointer;
			}

			.qtyNumber {
				color: $white;
				font-family: 'Bebas Neue', cursive;
				font-size: 28px;
			}
		}
	}
}

.menuItem {
	box-sizing: border-box;
	width: 100%;
	max-width: 335px;
	height: 110px;
	margin: 0 auto 22px;
	border-radius: $menu-item-border-radius;
	box-shadow: 0 2px 6px 0 #00000033;
	background-color: $white;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.imageContainer {
		box-sizing: border-box;
		width: 78px;
		height: 100%;
		background-color: $accent;
		border-radius: $menu-item-border-radius 0 0 $menu-item-border-radius;
		overflow: hidden;
		background-size: cover;
		background-position: center;
		cursor: pointer;
	}

	.infos {
		box-sizing: border-box;
		position: relative;
		width: calc(100% - 78px);
		height: 100%;
		padding: 12px;

		.name {
			display: block;
			font-family: 'Bebas Neue', cursive;
			font-size: 18px;
			line-height: 20px;
			color: $black;
			margin-bottom: 3px;
			cursor: pointer;
		}

		.description {
			display: block;
			font-family: 'TradeGothicLT-Bold';
			font-size: 12px;
			line-height: 13px;
			color: $black;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2; /* number of lines to show */
			-webkit-box-orient: vertical;
			margin-bottom: 12px;
			cursor: pointer;
		}

		.price {
			box-sizing: border-box;
			display: block;
			width: 70px;
			height: 25px;
			background-color: $black;
			border-radius: 13px;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				font-family: 'Bebas Neue', cursive;
				font-size: 15px;
				color: $white;
			}
		}

		.qty {
			position: absolute;
			bottom: 8px;
			right: 8px;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 30px;
				height: 30px;
				cursor: pointer;
				background-color: $accent;
				border-radius: 100%;
			}

			.qtyNumber {
				font-size: 20px;
				margin: 0 10px;
			}
		}
	}
}
