@import '../../shared/theme/colors.scss';
@import '../../shared/theme/variables.scss';
@import '../../shared/theme/typography.scss';

.cancelLink {
	color: $black;
	font-family: 'TradeGothicLT-Bold';
	font-size: 14px;
	text-transform: capitalize;
	text-decoration: underline;
	cursor: pointer;
}

.backLink {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

.title {
	color: $black;
	font-family: 'Bebas Neue', cursive;
	font-size: 20px;
	display: block;
	margin: 0 auto;
	text-align: center;
}

.grayBackground {
	background-color: $background;
}
