/* ****** */
/* HEADER */
/* ****** */
$header-height: 100px;

/* ***** */
/* INPUT */
/* ***** */

$input-height: 48px;
$input-border-width: 2px;
$input-padding: 14px;
$input-border-radius: 8px;
$input-icon-size: 24px;

/* ********** */
/* CATEGORIES */
/* ********** */

$category-border-radius: 8px;

/* ********* */
/* MENU ITEM */
/* ********* */

$menu-item-border-radius: 10px;
$modal-button-border-radius: 10px;

/* ****** */
/* BUTTON */
/* ****** */

$button-border-radius: 8px;
$button-text-color: #fff;
