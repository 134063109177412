@import '../../shared/theme/colors.scss';
@import '../../shared/theme/variables.scss';

.background {
	background-color: $background;
	padding-top: calc(#{$header-height} + 50px);
	min-height: calc(100vh - calc(#{$header-height} + 52px));
	overflow: scroll;
}

.backgroundWhite {
	background-color: $white;
	padding-top: calc(#{$header-height} + 50px);
	min-height: calc(100vh - calc(#{$header-height} + 52px));
	overflow: scroll;
}
