@import '../../shared/theme/variables.scss';
@import '../../shared/theme/colors.scss';

.header {
	box-sizing: border-box;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
	width: 100%;
	max-width: 375px;
	margin: 0 auto;
	height: $header-height;
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $white;

	.logo {
		width: 80px;
		height: 80px;
	}

	.center {
		width: calc(100% / 3);
	}

	.sideLeft {
		width: calc(100% / 3);
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.sideRight {
		width: calc(100% / 3);
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}
