@import '../../shared/theme/colors.scss';
@import '../../shared/theme/variables.scss';
@import '../../shared/theme/typography.scss';

.cancelLink {
	color: $black;
	font-family: 'TradeGothicLT-Bold';
	font-size: 14px;
	text-transform: capitalize;
}

.languageSelect {
	border-radius: 5px;
	padding: 8px;
	border-color: $gray;
}

.cartLink {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;

	.cartBadge {
		width: 15px;
		height: 15px;
		background-color: $red;
		border-radius: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 8px;

		span {
			color: $white;
			font-size: 12px;
		}
	}
}

.grayBackground {
	background-color: $background;
}
