@import '../../shared/theme/colors.scss';
@import '../../shared/theme/variables.scss';
@import '../../shared/theme/typography.scss';

@keyframes slide {
	0% {
		padding: 0 0 0 80px;
	}
	35% {
		padding: 0 0 0 40px;
	}
	70% {
		padding: 0 0 0 80px;
	}
	100% {
		padding: 0 0 0 20px;
	}
}

.categoriesContainer {
	position: fixed;
	top: $header-height;
	left: 0;
	right: 0;
	z-index: 1;
	width: 100%;
	width: 100%;
	max-width: 375px;
	margin: 0 auto;
	background-color: $white;
	background: linear-gradient(
		0deg,
		transparent calc(100% - 10px),
		$white calc(100% - 10px)
	);

	.slider {
		box-sizing: border-box;
		width: 100%;
		padding: 0 0 0 20px;
		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		overflow: scroll;

		&.animated {
			padding: 0 0 0 80px;
			animation: slide linear 0.8s 1;
			animation-fill-mode: forwards;
			animation-delay: 2s;
		}
	}

	.category {
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 32px;
		padding: 12px 30px;
		margin-right: 10px;
		margin-bottom: 10px;
		border-radius: $category-border-radius;
		background-color: $black;
		box-shadow: 0 2px 6px 0 #00000033;
		cursor: pointer;

		&:last-of-type {
			margin-right: 40px;
		}

		&.selected {
			background-color: $accent;
		}

		span {
			color: $white;
			font-family: 'TradeGothicLT-Bold';
			text-transform: uppercase;
			white-space: nowrap;
		}
	}
}
