@import '../../shared/theme/colors.scss';
@import '../../shared/theme/variables.scss';
@import '../../shared/theme/typography.scss';

.loader {
	box-sizing: border-box;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	background-color: rgba($white, 0.8);
	border: solid 2px $accent;
	display: flex;
	justify-content: center;
	align-items: center;

	span {
		font-family: 'TradeGothicLT-Bold';
		font-size: 14px;
		text-align: center;
	}
}
